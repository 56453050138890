









import { isInQYWeChatBrowser } from '@/shared/utils'
import { defineComponent } from '@vue/composition-api'
import { authQYWeChat, authWeChat } from '@/shared/wx-auth'
import { saveLoginToken } from '@/api/login'
import ContentForSeller from './ContentForSeller.vue'
import ContentForUser from './ContentForUser.vue'

/**
 * 1. 判断微信 or 企业微信
 * 2. 判断登录 or 未登录
 * 3. 根据不同端, 渲染不同数据
 * 展业员专属功能: 转发按钮、展业员基本信息
 * 用户端专属功能: 转发提示弹框
 */
export default defineComponent({
  components: {
    ContentForSeller,
    ContentForUser,
  },
  data() {
    return {
      isSeller: isInQYWeChatBrowser(),
      isAuthed: false,
    }
  },
  async mounted() {
    const isInQYWeChat = isInQYWeChatBrowser()
    let token = ''
    if (isInQYWeChat) {
      token = await authQYWeChat(this.$route)
    } else {
      token = await authWeChat(this.$route)
    }

    if (token) {
      saveLoginToken({ token })
      this.isAuthed = true
    } else {
      // do nothing
    }
  },
})















import { T_ContentBaseDetail } from '@/api/content'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    contentDetail: {
      type: Object as PropType<T_ContentBaseDetail>,
      required: true,
    },
  },
  emits: ['onRead'],
  data() {
    return {
      content: '',
      contentHtml: '',
      mpCH: 'unset',
    }
  },
  mounted() {
    this.$props.contentDetail.morningPaperType !== 'IMAGE' && this.onSetGlobalSize()
    const rootEl = window
    rootEl?.addEventListener('scroll', this.onScroll.bind(this))
    this.$props.contentDetail.morningPaperType !== 'IMAGE' && rootEl?.addEventListener('message', this.onMessage)
  },
  destroyed() {
    const rootEl = window
    rootEl?.removeEventListener('scroll', this.onScroll.bind(this))
    this.$props.contentDetail.morningPaperType !== 'IMAGE' && rootEl?.removeEventListener('message', this.onMessage)
  },
  methods: {
    onScroll() {
      const contentRootDetail = document.querySelector('.no-trans-content-root')
      let ratio = (window.scrollY + window.screen.height) / (contentRootDetail?.clientHeight || 0)
      if (ratio > 1) {
        ratio = 1
      }
      this.$emit('onRead', ratio.toFixed(2))
    },
    async onMessage(event: any) {
      const data = event.data
      if (data.source === 'react-devtools-content-script' || data.type) {
        return
      }
      const dataParse = JSON.parse(data)
      if (!dataParse || !dataParse.outid) {
        return
      }
      switch (dataParse.outid) {
        case 'h5Detail':
          {
            this.contentHtml = dataParse.contentHtml
          }
          break
        case 'isShowPo':
          {
            if (dataParse.isOpen) {
              const swEle = document.querySelector('.switch-box') as any
              const bE = document.querySelector('.no-trans-content-baseinfo-box') as any
              // const seEle = document.querySelector('.no-trans-seller-info') as any
              let cH = 0
              if (swEle) {
                cH = cH + swEle.clientHeight
              }
              if (bE) {
                cH = cH + bE.clientHeight + 66
              }
              this.mpCH = `calc(100vh - ${cH}px)`
            } else {
              this.mpCH = 'unset'
            }
          }
          break
      }
    },
    onSetGlobalSize() {
      const rate = 0.12
      const setGlobalSize = function () {
        let clientWidth = document.body.clientWidth
        document.documentElement.style.fontSize = (clientWidth - 32) * rate + 'px'
      }
      setGlobalSize()
    },
  },
})

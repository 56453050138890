









import { T_ContentBaseDetail } from '@/api/content'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    contentDetail: {
      type: Object as PropType<T_ContentBaseDetail>,
      required: true
    }
  },
  emits: ['onRead'],
  mounted() {
    const rootEl = window
    rootEl?.addEventListener('scroll', this.onScroll.bind(this))
  },
  destroyed() {
    const rootEl = window
    rootEl?.removeEventListener('scroll', this.onScroll.bind(this))
  },
  methods: {
    onScroll() {
      const contentRootDetail = document.querySelector('.no-trans-content-root')
      let ratio = (window.scrollY + window.screen.height) / (contentRootDetail?.clientHeight || 0)
      if (ratio > 1) {
        ratio = 1
      }
      this.$emit('onRead', ratio.toFixed(2))
    }
  }
})

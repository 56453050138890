






























import { ContentType, T_ContentBaseDetail } from '@/api/content'
import { defineComponent, PropType } from '@vue/composition-api'
import ArticleContent from './ContentDetailArticle.vue'
import MpContent from './ContentDetailMp.vue'
import VideoContent from './ContentDetailVideo.vue'

export default defineComponent({
  components: {
    ArticleContent,
    VideoContent,
    MpContent,
  },
  props: {
    contentDetail: {
      type: Object as PropType<T_ContentBaseDetail>,
      required: true,
    },
    isLiked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['like', 'read'],
  data() {
    return {
      isVideo: this.contentDetail.contentType === ContentType.VIDEO,
      isArticle: this.contentDetail.contentType === ContentType.ARTICLE,
    }
  },
  methods: {
    onClickLike() {
      this.$emit('onClickLike')
    },
    onRead(progress: number) {
      this.$emit('read', progress)
    },
  },
})







































import { defineComponent } from '@vue/composition-api'
import ContentList from '@/components/Content/ContentList.vue'
import { useList } from '@/hook/useList'
import {
  getRecommendContentList,
  T_Content
} from '@/api/content'
import { useBaseInfo } from '@/hook/useBaseInfo'

export default defineComponent({
  inject: ['reloadRoutePage'],
  components: {
    ContentList
  },
  props: {
    contentId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      required: true
    },
    sellerId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { riversAppId } = useBaseInfo()
    const contentListRefs = useList({
      api: getRecommendContentList.bind(this, props.userId, props.contentId),
      searchParams: {}
    })

    return {
      ...contentListRefs,
      riversAppId
    }
  },
  methods: {
    toContentDetail(content: T_Content) {
      this.$router.push(
        `/${this.riversAppId}/content/${content.privateContentPoolId}/${content.contentId}/${content.contentType}/${this.sellerId}`
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(this as any).reloadRoutePage()
    }
  }
})

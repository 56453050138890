import { CustomerOrderNum, T_PageParams, T_PageRes } from '@/hook/useList'
import { AxiosRequestHeaders } from 'axios'
import { api } from './api'
import { ContentType, T_Content } from './content'
import { T_SellerBaseInfo, T_SellerCardInfo, T_ShareInfo } from './profile'
import { Action } from './statistics'
export enum UserLevel {
  VISIT = 'VISIT',
  INTERESTED = 'INTERESTED',
  IMPORTANCE = 'IMPORTANCE'
}

export const UserLevelNames = {
  [UserLevel.VISIT]: '访问用户',
  [UserLevel.INTERESTED]: '感兴趣用户',
  [UserLevel.IMPORTANCE]: '重要用户',
}

export enum UserRole {
  SELLER = 'SELLER',
  CUSTOMER = 'CUSTOMER'
}

export enum UserGender {
  WOMAN = 'WOMAN',
  MAN = 'MAN'
}

export const UserGenderName = {
  [UserGender.WOMAN]: '女',
  [UserGender.MAN]: '男',
}

export type T_UserBaseInfo = {
  id: string,
  name: string,
  avatar: string,
  nickName: string,
  // gender: string, 性别目前不返回
  userRole: UserRole,
  createDate: string,
  updateDate: string,
  phone: string,
  level: UserLevel,
  lastReadTime: string,
  source?: string
}

export const EmptyUserBaseInfo: T_UserBaseInfo = {
  id: '',
  name: '',
  avatar: '',
  nickName: '',
  // gender: '', 性别目前不返回
  userRole: UserRole.CUSTOMER,
  createDate: '',
  phone: '',
  updateDate: '',
  level: UserLevel.VISIT,
  lastReadTime: ''
}

export type T_GetCustomerParams = {
  name: string,
  orderNum: CustomerOrderNum
}

export type T_ReadContent = {
  shardUserId: string,
  readDuration: number,
  action: Action,
  updateDate: string,
  readDate: string,
  content: T_Content
}

export type T_Customer = {
  baseInfo: T_UserBaseInfo,
  lastReadDate: string,
  contentRecord: {
    recReadSize: number, // 推荐浏览次数
    readContentList: T_ReadContent[]
  },
  sellerCustomer: boolean,
  externalUserId: string,
  readDuration: number,
  readDate: string,
  tags: string[]
}

export function getCustomerList(params: T_PageParams & T_GetCustomerParams): Promise<T_PageRes<T_Customer[]>> {
  params.orderNum = params.orderNum || CustomerOrderNum.READING
  return api.get('/fishes/v2/customer/customers', { params })
}

export function getCustomerInfo(id: string): Promise<T_Customer> {
  return api.get(`/fishes/v2/customer/${id}`)
}

export type T_DateParams = {
  startDate: string,
  endDate: string,
}

export type T_CustomerReadContent = {
  contentId: string,
  contentType: ContentType,
  title: string,
  duration: number,
  readDuration: number,
  words: number,
  categoryId: string,
  createContentTime: string,
  publishContentTime: string,
  uri: string
}

export type T_ReadDetail = {
  statisticsTime: string,
  readContentList: T_CustomerReadContent[]
}


export function getCustomerReadList(customerId: string, dateParams: T_DateParams): Promise<T_PageRes<T_ReadDetail[]>> {
  return api.get(`/fishes/v2/content/${customerId}/records`, {
    params: dateParams
  })
}

export type T_SellerInfoForCustomer = T_SellerBaseInfo & {
  businessCard: T_SellerCardInfo,
  newsfeedId: string
}

export function getSpecifiedSellerInfo(sellerId: string, headers?: AxiosRequestHeaders): Promise<T_SellerInfoForCustomer> {
  return api.get(`/fishes/v2/seller/${sellerId}/info`, {
    headers
  })
}

//  用户群体
export type T_Tag = {
  id: string
  name: string
  score: number
}

export type T_IntervalItem = {
  name: string
  readNum: number
  timeStamp: number
}

export type T_ReadBehaviorStatisticsItem = {
  desc: string
  intervals: T_IntervalItem[]
  timeStamp: number
  weekDay: string
}

export type T_CustomerTrans = {
  note: string
  ratio: number
  userLevel: string
  userNum: number
}

export type T_UserGroupRes = {
  customerStatistics: T_CustomerTrans[]
  readBehaviorStatistics: T_ReadBehaviorStatisticsItem[]
  sellerId: string
  tagsStatistics: T_Tag[]
}
export function getUserGroupPortrait(): Promise<T_UserGroupRes> {
  return api.get(`/fishes/v2/rivers/web/userGroupPortrait`)
}

export enum CustomerType {
  VISIT = 'VISIT', // 访问用户
  INTERESTED = 'INTERESTED', // 感兴趣用户
  IMPORTANCE = 'IMPORTANCE', // 重要用户
}

export const CustomerTypeNames = {
  [CustomerType.VISIT]: '访问用户',
  [CustomerType.INTERESTED]: '感兴趣用户',
  [CustomerType.IMPORTANCE]: '重要用户',
}

export type T_SellerCustomer = {
  baseInfo: T_UserBaseInfo,
  browseNumber: number,
  tags: string[]
}

export type T_GetCustomerListParams = {
  type: CustomerType
}

export function getSellerCustomerList(params: T_PageParams & T_GetCustomerListParams): Promise<T_PageRes<T_SellerCustomer[]>> {
  return api.get('/fishes/v2/rivers/web/seller/customers', { params })
}

export type T_SellerCustomerStatistic = {
  note: string, // 描述
  ratio: number, // 用户转化数量
  userLevel: CustomerType, // 用户级别
  userNum: number, // 用户数量
}

export function getSellerCustomerStatistic(): Promise<T_SellerCustomerStatistic[]> {
  return api.get('/fishes/v2/rivers/web/seller/statistic')
}


// 获取用户分享链路
export type T_CustomerShareListRes = {
  page: {
    pageNumber: number
    pageSize: number
    total: number
  },
  records: T_ShareInfo[]
}
export function getCustomerShareList(userId: string, shareId: string, params: T_PageParams): Promise<T_CustomerShareListRes> {

  return api.get(`/fishes/v2/${userId}/${shareId}/shareList`, {
    params
  })
}

export type T_CustomerRecommendParams = {
  userId: string,
  contentId?: string
} & T_PageParams

export function getCustomerRecommendContentList(params: T_CustomerRecommendParams): Promise<T_PageRes<T_Content[]>> {
  return api.get(`/fishes/v2/${params.userId}/${params.contentId || 'test'}/contents`)
}
// 获取用户个体画像
export type T_PreferenceForecastItem = {
  id: string
  name: string
  score: number
}
export type T_UserAnalysis = {
  activeDaySum: number
  activeSection: string[]
  browserNum: number
  readTimeSum: number
  tags: string[]
}
export type T_CustomerPortraitRes = {
  baseInfo: T_UserBaseInfo,
  preferenceForecast: T_PreferenceForecastItem[]
  userAnalysis: T_UserAnalysis
}
export function getCustomerPortrait(userId: string): Promise<T_CustomerPortraitRes> {
  // return mockReq({
  //   baseInfo: {
  //     id: '1',
  //     name: '哈哈哈',
  //     avatar: '啊实打实',
  //     nickName: '爱仕达撒',
  //     // gender: '', 性别目前不返回
  //     userRole: UserRole.CUSTOMER,
  //     createDate: '',
  //     phone: '111111111',
  //     updateDate: '',
  //     level: UserLevel.VISIT
  //   },
  //   preferenceForecast: [
  //     {
  //       id: '1',
  //       name: '什么',
  //       score: 0,
  //     },
  //     {
  //       id: '2',
  //       name: '什么',
  //       score: 0,
  //     },
  //     {
  //       id: '3',
  //       name: '什么',
  //       score: 0,
  //     },
  //   ],
  //   userAnalysis: {
  //     activeDaySum: 0,
  //     activeSection: ['10点-12点', '16点-18点'],
  //     browserNum: 0,
  //     readTimeSum: 0,
  //     tags: ['第一', '第二', '第三', '第四', '第五']
  //   }
  // })
  return api.get(`/fishes/v2/rivers/web/${userId}/userPortrait`)
}

// 获取用户轨迹
export enum ActionType {
  VIEW_PHONE = 'VIEW_PHONE',
  UNLIKE = 'UNLIKE',
  VIEW_NAME_CARD = 'VIEW_NAME_CARD',
  LIKE = 'LIKE',
  SHARE = 'SHARE',
  PAGE_OUT = 'PAGE_OUT'
}

export type T_TracksParams = T_PageParams & {
  userId: string
}
export type T_BaseContent = {
  browseDuration: number,
  browseProgress: number,
  contentEnumType: ContentType,
  contentId: string,
  contentPoolId: string,
  contentTags: string[],
  contentTitle: string,
  predictBrowseDuration: number,
  targetCategory: string,
  targetLength: number,
  targetTag: string,
  targetUrl: string,
  shareId?: string
}
export type T_TracksList = {
  actionEnum: ActionType,
  actionTime: string,
  baseContent: T_BaseContent,
  shareId: string,
  shareInfos: T_ShareInfo[],
  shareTotal: number
}
export function getCustomerTracks(params: T_TracksParams): Promise<T_PageRes<T_TracksList[]>> {
  // userId: string, params:T_PageParams
  return api.get(`/fishes/v2/rivers/web/${params.userId}/tracks`, {
   params,
  })
}































import { SellerCardVisible } from '@/api/profile'
import { isSellerCardVisible } from '@/shared/common'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    cardVisible: {
      type: String as PropType<SellerCardVisible>,
      default: true
    },
    recommendVisible: {
      type: String as PropType<SellerCardVisible>,
      default: true
    },
    sellerInfoVisible: {
      type: String as PropType<SellerCardVisible>,
      default: true
    }
  },
  emits: ['changeVisibleCard', 'changeVisibleRecommend', 'changeSellerInfoVisible'],
  setup(props, { emit }) {
    const changeCardVisible = () => {
      emit(
        'changeVisibleCard',
        reverseVisible(props.cardVisible)
      )
    }

    const changeRecommendVisible = () => {
      emit(
        'changeVisibleRecommend',
        reverseVisible(props.recommendVisible)
      )
    }

    const changeSellerInfoVisible = () => {
      emit('changeSellerInfoVisible', reverseVisible(props.sellerInfoVisible))
    }

    return {
      changeCardVisible,
      changeRecommendVisible,
      changeSellerInfoVisible,
      isSellerCardVisible
    }
  }
})

function reverseVisible(visible: SellerCardVisible) {
  return isSellerCardVisible(visible) ? SellerCardVisible.CLOSED : SellerCardVisible.OPEN
}

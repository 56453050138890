























import { useProfile } from '@/hook/useBaseInfo'
import { useContentDetail } from '@/hook/useContent'
import { defineComponent } from '@vue/composition-api'
import ContentInfos from './ContentInfos.vue'

/**
 * 展示展业员信息
 * 展示内容详情
 * 展示个人名片
 * 展示相关推荐
*/
export default defineComponent({
  components: {
    ContentInfos
  },
  setup() {
    const sellerCtx = useProfile()
    const contentCtx = useContentDetail()

    return {
      sellerCtx,
      contentCtx,
    }
  }
})

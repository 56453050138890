import { getCurrentInstance, nextTick, onMounted, onUnmounted, reactive, ref } from '@vue/composition-api'
import { ContentType, getContentDetail, getContentSwitchVisible, setContentSwitchVisible, T_ContentBaseDetail } from '@/api/content'
import { EmptyContent } from '@/constant/content'
import { copy, EventEmitter, getURLQuery, htmlToText, setTitle } from '@/shared/utils'
import { Dialog, Toast } from 'vant'
import { SellerCardVisible, T_SellerBaseInfo } from '@/api/profile'
import { useRoute, useRouter } from './useRouter'
import { initReporter } from '@/shared/report'
import { useBaseInfo } from './useBaseInfo'
import { listenShared, listenSharedTimeline, reloadWXInstance, shareMoments } from '@/shared/wx'
import { getLocalCorpDetail } from '@/api/login'
import { ylLog } from '@/shared/log'
import { nanoid } from 'nanoid'

export function useContentDetail() {
  const route = useRoute()
  const contentInfo = reactive<T_ContentBaseDetail>(copy(EmptyContent))
  const isContentLoaded = ref(false)

  getContentDetail(route.params.contentId as string, route.params.contentPoolId as string, route.params.sellerId as string)
    .then(content => {
      Object.assign(contentInfo, content)
    })
    .catch(err => {
      Dialog.alert({ message: err.message })
    })
    .finally(() => {
      isContentLoaded.value = true
    })

  return {
    contentInfo,
    isContentLoaded,
  }
}

export function useContentSwitch() {
  const route = useRoute()
  const baseInfo = {
    sellerId: route.params.sellerId as string,
    contentId: route.params.contentId as string,
  }
  const contentSwitchInfo = reactive({
    cardSwitch: SellerCardVisible.CLOSED,
    recommendSwitch: SellerCardVisible.CLOSED,
    contactMeSwitch: SellerCardVisible.CLOSED,
  })

  getContentSwitchVisible(baseInfo).then(res => {
    Object.assign(contentSwitchInfo, res)
  })

  function changeVisible(key: 'cardSwitch' | 'recommendSwitch' | 'contactMeSwitch', visible: SellerCardVisible) {
    contentSwitchInfo[key] = visible
    setContentSwitchVisible({
      ...baseInfo,
      ...contentSwitchInfo,
    }).catch(err => Toast.fail(err.message))
  }

  return {
    contentSwitchInfo,
    changeVisible,
  }
}

export type T_SetContentActionParams = {
  content: T_ContentBaseDetail
  sellerInfo: T_SellerBaseInfo
  userId: string
  contentType: string
}

export function useContentActions({ userId, content, sellerInfo, contentType }: T_SetContentActionParams) {
  const { riversAppId } = useBaseInfo()
  const ctx = getCurrentInstance()
  const router = useRouter()

  const routeQuery = getURLQuery(location.href)
  const defaultSharedInfo = getDefaultSharedInfo(routeQuery, sellerInfo)
  let newSharedId = nanoid()

  const reportCtx = initReporter({
    appkey: sellerInfo.newsfeedId,
    userId,
    content,
    sellerInfo,
    sharedInfo: defaultSharedInfo,
    corpId: riversAppId,
  })
  const startReadTime = Date.now()

  let browseProgress = 0,
    isBrowseEnd = false

  // if (contentType !== 'MORNING_PAPER') {
  //   // 上报进入页面
  //   reportCtx.enterContent()
  // }

  reportCtx.enterContent()

  // 改变当前路由 query, 使其分享时, query 数据为新值
  changeRouteShared(newSharedId)

  listenWeChat()

  setTitle(content.title)

  let leaveFlag = false

  onMounted(() => {
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // 监听离开页面并上报
    if (isIOS) {
      window.addEventListener('pagehide', reportLeave)
    } else if (isAndroid) {
      window.addEventListener('visibilitychange', function () {
        if (document.hidden) {
          reportLeave()
        }
      })
    } else {
      window.addEventListener('beforeunload', reportLeave)
    }
    // window.addEventListener('unload', reportLeave)

    EventEmitter.on('RouterChanged', onRouterChanged)
  })

  onUnmounted(() => {
    // window.removeEventListener('unload', reportLeave)
    EventEmitter.off('RouterChanged', onRouterChanged)
  })

  function setBrowseProgress(progress: number) {
    if (isBrowseEnd) {
      return
    }
    // ylLog('Set Progress', progress)
    browseProgress = progress
    if (progress >= 1) {
      reportLeave()
      reportCtx.shareContentReadEnd()
      isBrowseEnd = true
    }
  }

  function onRouterChanged() {
    nextTick(() => {
      const newRoute = ctx?.proxy.$route
      const isPageChanged = newRoute?.params.contentId !== content.contentId
      isPageChanged && reportLeave()
      ylLog('onRouterChanged', newRoute?.params.contentId)
    })
  }

  function share() {
    reportCtx.shareContent(newSharedId)
    newSharedId = nanoid()
    changeRouteShared(newSharedId)
  }

  function like() {
    reportCtx.likeContent()
    content.zanNumber++ // unfriendly 直接改变传入数据
  }

  function unlike() {
    reportCtx.unlikeContent()
    content.zanNumber--
  }

  function reportLeave() {
    // if (leaveFlag || contentType === 'MORNING_PAPER') return
    if (leaveFlag) return
    leaveFlag = true
    reportCtx.leaveContent({
      browseDuration: Date.now() - startReadTime,
      browseProgress: browseProgress || 1,
    })
  }

  function changeRouteShared(newShareId: string) {
    router.replace({
      query: {
        shareId: newShareId,
        preShareId: defaultSharedInfo.shareId,
        userSourceId: userId,
      },
    })
    listenWeChat()
  }

  function setShareTimelineDesc(desc: string) {
    setShareTimeline(desc, content, {
      onShared: share,
    })
  }

  function shareMoment(desc: string) {
    const corpInfo = getLocalCorpDetail() // 只有展业员端能取到此信息
    shareMoments({
      title: content.title,
      link: location.href,
      desc,
      imgUrl: getSharedContentImage(content, corpInfo?.corpLogo || ''),
    })
  }

  function listenWeChat() {
    // setTimeout, 使路由或 DOM 操作完成后再设置
    setTimeout(() => {
      listenWeChatShare(riversAppId, content, {
        onShared: share,
      })
    })
  }

  return {
    like,
    unlike,
    setShareTimelineDesc,
    shareMoment,
    setBrowseProgress,
    ...reportCtx,
  }
}

function getDefaultSharedInfo(query: Record<string, string>, sellerInfo: T_SellerBaseInfo) {
  const shareId = query.shareId || ''
  const { preShareId = '', userSourceId = sellerInfo.id } = query

  return { shareId, preShareId, userSourceId }
}

type ListenShareCallbacks = {
  onShared: () => void
}

function listenWeChatShare(riversAppId: string, content: T_ContentBaseDetail, callbacks: ListenShareCallbacks) {
  reloadWXInstance(riversAppId).then(() => {
    const corpInfo = getLocalCorpDetail() // 只有展业员端能取到此信息
    const desc = (getSharedContentText(content) || '').replace(/\s/g, '')
    ylLog('设置微信转发描述', desc)
    const baseInfo = {
      title: content.title,
      link: window.location.href,
      desc,
      imgUrl: getSharedContentImage(content, corpInfo?.corpLogo || ''),
    }
    listenShared(baseInfo, () => {
      ylLog('监听到微信转发')
      callbacks.onShared()
    })
    listenSharedTimeline(baseInfo, () => {
      ylLog('监听到朋友圈转发')
      callbacks.onShared()
    })
  })
}

function setShareTimeline(desc: string, content: T_ContentBaseDetail, callbacks: ListenShareCallbacks) {
  const corpInfo = getLocalCorpDetail() // 只有展业员端能取到此信息
  const baseInfo = {
    title: content.title,
    link: window.location.href,
    desc: desc,
    imgUrl: getSharedContentImage(content, corpInfo?.corpLogo || ''),
  }
  listenSharedTimeline(baseInfo, () => {
    ylLog('监听到朋友圈转发')
    callbacks.onShared()
  })
}

function getSharedContentText(contentBaseInfo: T_ContentBaseDetail) {
  if (contentBaseInfo.summary) {
    /* 优先: 摘要 */
    return contentBaseInfo.summary
  } else if (contentBaseInfo.content) {
    /* 正文 */
    return htmlToText(contentBaseInfo.content)
  } else if (contentBaseInfo.author && contentBaseInfo.author.authorName) {
    /* 作者的文章 */
    const contentTypeText = {
      [ContentType.VIDEO]: '视频',
      [ContentType.ARTICLE]: '文章',
      [ContentType.MORNING_PAPER]: '早报',
    } as Record<string, string>

    const text = contentTypeText[contentBaseInfo.contentType] || '文章'
    return `${contentBaseInfo.author.authorName} 的${text}`
  } else {
    return ''
  }
}

function getSharedContentImage(contentBaseInfo: T_ContentBaseDetail, corpLogo: string) {
  /* 优先: 封面图第一张 */
  if (contentBaseInfo.coverPics && contentBaseInfo.coverPics.length && contentBaseInfo.coverPics[0].url) {
    return contentBaseInfo.coverPics[0].url
  } else {
    return corpLogo
  }
}

import { api } from "./api"

// 目前展业服务的上报只用到这些 (大数据用到的更多)
export enum ReportAction {
  PAGE_IN = 'PAGE_IN',
  PAGE_OUT = 'PAGE_OUT',
  VIEW_NAME_CARD = 'VIEW_NAME_CARD'
}

export type T_ReportActionParams = {
  appId: string,
  browseProgress: number,
  customerId: string,
  sellerId: string,
  eventType: ReportAction
}

/* 本期先适配服务, 调用展业服务接口. 后续删除. 统一走大数据上报 */
export function reportUserAction(params:T_ReportActionParams ) {
  return api.put('/fishes/v2/rivers/web/report/event', params)
}















import { T_ContentBaseDetail } from '@/api/content'
import { defineComponent, PropType } from '@vue/composition-api'
import { getContentFirstCover } from '@/shared/common'

export default defineComponent({
  props: {
    contentDetail: {
      type: Object as PropType<T_ContentBaseDetail>,
      required: true
    },
  },
  emits: ['onRead'],
  data() {
    return {
      getContentFirstCover,
      videoParams: {
        totalTime: 0,
        playedTime: 0
      }
    }
  },
  methods: {
    onVideoCanPlay(evt: { target: HTMLVideoElement }) {
      this.videoParams.totalTime = evt.target.duration
    },
    onVideoTimeupdate(evt: { target: HTMLVideoElement }) {

      const currentTime = evt.target.currentTime
      if (
        this.videoParams.playedTime >= this.videoParams.totalTime
        || currentTime < this.videoParams.playedTime
      ) {
        return
      }
      this.videoParams.playedTime = currentTime
      const ratio = currentTime / this.videoParams.totalTime
      this.$emit('onRead', ratio.toFixed(2))
    }
  }
})

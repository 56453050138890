


































































import { getContentReviewsByText, T_ContentBaseDetail, getLikeStatus } from '@/api/content'
import { T_SellerInfoForCustomer } from '@/api/customer'
import { T_SellerCardInfo } from '@/api/profile'
import { useContentActions, useContentSwitch } from '@/hook/useContent'
import { isSellerCardVisible } from '@/shared/common'
import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'
// import SellerInfo from '@/components/BaseInfo/SellerInfo.vue'
import SellerCard from '@/components/Profile/Card.vue'
import ContentRecommend from '@/components/Content/ContentRecommend.vue'
import SwitchControlVisible from '@/components/Tools/switchControlVisible.vue'
import ContentDetail from '@/components/Content/ContentDetail.vue'
import { ylLog } from '@/shared/log'
import { htmlToText, setClipboardData } from '@/shared/utils'
import { Toast } from 'vant'

export default defineComponent({
  components: {
    // SellerInfo,
    SellerCard,
    ContentRecommend,
    SwitchControlVisible,
    ContentDetail,
  },
  props: {
    isSeller: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      required: true,
    },
    contentDetail: {
      type: Object as PropType<T_ContentBaseDetail>,
      required: true,
    },
    sellerInfo: {
      type: Object as PropType<T_SellerInfoForCustomer>,
      default: null,
    },
    sellerCardInfo: {
      type: Object as PropType<T_SellerCardInfo>,
      required: true,
    },
  },
  setup(props) {
    const contentSwitchCtx = useContentSwitch()
    const isSellerInfoVisible = computed(() => {
      return !props.isSeller && isSellerCardVisible(contentSwitchCtx.contentSwitchInfo.contactMeSwitch)
    })
    const actionCtx = useContentActions({
      userId: props.userId,
      content: props.contentDetail,
      sellerInfo: props.sellerInfo,
      contentType: props.contentDetail.contentType,
    })

    ylLog('Get LikeStatus UserId', props.userId)
    const likeCtx = useLikeStatus(props.userId, props.contentDetail.contentId)

    const reviewsCtx = useReviews(props.contentDetail, props.isSeller)
    const isShowTimelinePrompt = ref(false)

    function setTimelineDesc() {
      setClipboardData(reviewsCtx.selectedReview.value)
      actionCtx.setShareTimelineDesc(reviewsCtx.selectedReview.value)
      reviewsCtx.isShowReview.value = false
      isShowTimelinePrompt.value = true
    }

    // 直接调用企微的分享客户朋友圈(暂未使用)
    function shareMoment() {
      actionCtx.shareMoment(reviewsCtx.selectedReview.value)
    }

    function onClickPhone() {
      actionCtx.viewUserPhone()
    }

    function onClickCard() {
      actionCtx.viewUserCard()
    }

    function onClickLike() {
      likeCtx.isLike.value ? actionCtx.unlike() : actionCtx.like()
      likeCtx.isLike.value = !likeCtx.isLike.value
    }

    return {
      contentSwitchCtx,
      isSellerInfoVisible,
      isSellerCardVisible,
      actionCtx,
      setTimelineDesc,
      isShowTimelinePrompt,
      onClickPhone,
      onClickCard,
      onClickLike,
      likeCtx,
      shareMoment,
      ...reviewsCtx,
    }
  },
})

function useLikeStatus(userId: string, contentId: string) {
  const isLike = ref(false)

  getLikeStatus(userId, contentId).then(like => {
    isLike.value = like
  })

  return {
    isLike,
  }
}

function useReviews(contentDetail: T_ContentBaseDetail, isSeller: boolean) {
  const reviewsList: string[] = reactive([])
  const isShowReview = ref(false)
  const selectedReview = ref('')
  const isRl = ref(false)

  // const { title, content } = contentDetail
  // const contentText = htmlToText(content)
  // const hasContentReivews = isSeller && title && contentText
  const hasContentReivews = isSeller
  const MaxReviewCount = 5

  function loadContentReviewsByText(isRebuild?: boolean) {
    isRl.value = true
    getContentReviewsByText(contentDetail.contentId, isRebuild)
      .then(res => {
        if (!res.forwards || !res.forwards.length) {
          isRl.value = false
          return
        }
        reviewsList.length = 0
        reviewsList.push(
          ...res.forwards
            .slice(0, MaxReviewCount)
            .map(forward => {
              return htmlToText(forward)
            })
            .filter(v => v)
        )
        if (reviewsList.length) {
          selectedReview.value = reviewsList[0]
        }
        isRl.value = false
      })
      .catch(err => {
        isRl.value = false
        Toast.fail(err)
      })
  }

  hasContentReivews && loadContentReviewsByText()

  function reloadContentReviewsByText() {
    loadContentReviewsByText(true)
  }

  return {
    reviewsList,
    isShowReview,
    selectedReview,
    reloadContentReviewsByText,
    isRl,
  }
}

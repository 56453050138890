import { ContentType, T_ContentBaseDetail } from '@/api/content'
import { T_SellerBaseInfo } from '@/api/profile'
import { ReportAction, reportUserAction } from '@/api/report'
import { API_CONFIG } from '@/config/api'
import { ylLog } from './log'
import { htmlToText } from './utils'

const ReportSourceType = 'rivers'

export type T_BrowseInfo = {
  browseProgress?: number, // 浏览进度. 取值: 0 - 1
  browseDuration?: number,
}

export type T_SharedInfo = {
  shareId: string,
  preShareId: string,
  userSourceId: string, // 用户来源。当前用户通过 谁（可能为一个展业员也可能是一个普通用户） 产生的用户行为
}

export type T_ReportInitParams = {
  appkey: string,
  userId: string,
  content: T_ContentBaseDetail,
  sellerInfo: T_SellerBaseInfo,
  sharedInfo: T_SharedInfo,
  corpId: string
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { Reporter } = require('../assets/js/FeedsDataReport.esm.js')

export function initReporter(params: T_ReportInitParams) {
  const articleLen = params.content.content ? htmlToText(params.content.content).length : 0
  const videoLen = typeof params.content.video?.duration === 'number' ? params.content.video.duration * 1000 : 0
  const reporter = new Reporter({
    host: API_CONFIG.DataReportBaseURL,

    appkey: params.appkey,
    userId: params.userId,
    sourceId: params.appkey,
    isLogin: true,
    sourceType: ReportSourceType,
    contentPoolId: params.content.privateContentPoolId,
    targetLength: params.content.contentType === ContentType.VIDEO ? videoLen : articleLen,
    userSourceId: params.sharedInfo.userSourceId
  })
  const content = params.content,
    sellerInfo = params.sellerInfo,
    sharedInfo = params.sharedInfo

  const baseReportInfo = formatReportContentInfo(content, sellerInfo, sharedInfo)

  const zhanyeServerReportParams = {
    appId: params.corpId,
    browseProgress: 1,
    customerId: params.userId,
    sellerId: params.sellerInfo.id,    
  }

  return {
    enterContent() {
      reporter.enterDetailPage(baseReportInfo)
      reportUserAction(Object.assign({}, zhanyeServerReportParams, { eventType: ReportAction.PAGE_IN }))
      ylLog('【上报进入页面】')
      ylLog('【上报进入页面】contentId 是', baseReportInfo.contentId)
      ylLog('【上报进入页面】userId 是', params.userId)
    },
    leaveContent(browserInfo: T_BrowseInfo) {
      reporter.leaveDetailPage(baseReportInfo, browserInfo)
      ylLog('上报离开页面', baseReportInfo.contentId)
    },
    shareContent(newSharedId: string) {
      const newSharedInfo = {
        shareId: newSharedId,
        preShareId: sharedInfo.shareId,
        userSourceId: sharedInfo.userSourceId,
      }
      const reportInfo = formatReportContentInfo(content, sellerInfo, newSharedInfo)
      reporter.shareContent(reportInfo)
      ylLog('上报分享', baseReportInfo.contentId)
      ylLog('【上报分享】')
      ylLog('【上报分享】contentId 是', baseReportInfo.contentId)
      ylLog('【上报分享】userId 是', params.userId)
    },
    shareContentReadEnd() {
      reportUserAction(Object.assign({}, zhanyeServerReportParams, { eventType: ReportAction.PAGE_OUT }))
    },
    likeContent() {
      reporter.likeContent(baseReportInfo)
      ylLog('上报点赞', baseReportInfo.contentId)
    },
    unlikeContent() {
      reporter.unlikeContent(baseReportInfo)
      ylLog('上报取消点赞', baseReportInfo.contentId)
    },
    viewUserPhone() {
      reporter.viewUserPhone(sellerInfo.id, baseReportInfo)
      ylLog('上报查看手机号', baseReportInfo.contentId)
    },
    viewUserCard() {
      reporter.viewUserCard(sellerInfo.id, baseReportInfo)
      reportUserAction(Object.assign({}, zhanyeServerReportParams, { eventType: ReportAction.VIEW_NAME_CARD }))
      ylLog('上报查看名片', baseReportInfo.contentId)
    }
  }
}

function formatReportContentInfo(content: T_ContentBaseDetail, sellerInfo: T_SellerBaseInfo, sharedInfo?: T_SharedInfo) {
  return {
    contentId: content.contentId,
    authorId: content.author.id || content.author.authorName,
    title: content.title,
    tag: content.tags,
    contentType: content.contentType,
    originId: sellerInfo.id,
    mediumId: content.contentId,
    mediumTitle: content.title,
    targetCategory: [content?.category?.categoryName || ''],
    // contentPoolId: content.content
    ...(sharedInfo || {})
  }
}
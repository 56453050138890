



























import { getSpecifiedSellerInfo } from '@/api/customer'
import { getEmptySellerCardInfo, getEmptySellerInfo } from '@/hook/useBaseInfo'
import { useRoute } from '@/hook/useRouter'
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { Dialog } from 'vant'
import { useContentDetail } from '@/hook/useContent'
import ContentInfos from './ContentInfos.vue'
import { getCustomerUserId } from '@/api/profile'
import { ylLog } from '@/shared/log'

/**
 * 展示展业员信息
 * 展示内容详情
 * 展示个人名片
 * 展示相关推荐
*/
export default defineComponent({
  components: {
    ContentInfos
  },
  setup() {
    const sellerCtx = useSellerInfo()
    const contentCtx = useContentDetail()
    const userCtx = useUserInfo()
    
    return {
      sellerCtx,
      contentCtx,
      userCtx
    }
  }
})

// 用户本身信息
function useUserInfo() {
  const userId = ref(''),
    isUserInfoLoaded = ref(false)

  getCustomerUserId().then(res => {
    userId.value = res.id
    isUserInfoLoaded.value = true
  }).catch(err => {
    Dialog.alert({ message: err.message })
  })

  return {
    userId,
    isUserInfoLoaded
  }
}

// 展业员信息
function useSellerInfo() {
  const $route = useRoute()
  const sellerInfo = reactive(getEmptySellerInfo()),
    sellerCardInfo = reactive(getEmptySellerCardInfo()),
    isProfileLoaded = ref(false)

  loadSellerInfo()
  
  function loadSellerInfo() {
    ylLog('开始获取展业员信息. 展业员 Id 为:', $route.params.sellerId)
    getSpecifiedSellerInfo($route.params.sellerId as string).then(info => {
      Object.assign(sellerInfo, info)
      Object.assign(sellerCardInfo, info.businessCard)
      isProfileLoaded.value = true
      ylLog('获取展业员信息成功. 展业员 Id 为:', $route.params.sellerId)
    }).catch(err => {
      Dialog.alert({ message: err.message })
      ylLog('获取展业员信息失败. 展业员 Id 为:', $route.params.sellerId)
    })
  }

  return {
    sellerInfo, sellerCardInfo, isProfileLoaded
  }
}

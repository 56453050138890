import { getToken } from '@/api/api'
import { getTokenByWeChatCode, getWeChatAppId, setLocalCorpDetail, WeChatType } from '@/api/login'
import { T_ContentDetailRouterQuery } from '@/router'
import { Dialog } from 'vant'
import { Route } from 'vue-router'
import { getURLQuery } from './utils'
import { getAuthUrl } from './wx-qy'

/* 用户通过微信进行授权 */
export async function authWeChat($route: Route) {
  const routeParams = $route.params,
    routeQuery = $route.query as T_ContentDetailRouterQuery & { code?: string }
  const riversAppId = routeParams.riversAppId as string

  const localToken = getToken()

  // 本地已有 token, 直接返回
  if (localToken) {
    return localToken
  }

  if (routeQuery.code) {
    // 微信授权的返回, 携带微信授权 code
    const { token } = await getTokenByWeChatCode(riversAppId, WeChatType.WE_CHAT, routeQuery.code).catch(handleGetTokenError)
    return token
  } else {
    // 获取 appId 并跳转至微信授权页面
    await jumpAuthUrl()
    return ''
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleGetTokenError(err: any) {
    // 40163, code 已被使用, 重新授权
    if (err.code === 40163) {
      await jumpAuthUrl()
    } else {
      Dialog.alert({ message: err.message })
    }
    return { token: '' }
  }

  async function jumpAuthUrl() {
    const { id: appId } = await getWeChatAppId(riversAppId, WeChatType.WE_CHAT)
    const redirectUrl = location.origin + location.pathname
    const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    window.location.href = authUrl
  }
}

/* 展业员企业微信授权 */
export async function authQYWeChat($route: Route) {
  const routeParams = $route.params,
    routeQuery = getURLQuery(location.href)
  const riversAppId = routeParams.riversAppId as string

  const localToken = getToken()

  if (localToken) {
    return localToken
  }

  if (routeQuery.code) {
    const { token } = await getTokenByWeChatCode(riversAppId, WeChatType.QY_WE_CHAT, routeQuery.code)
    return token
  } else {
    const { id: corpId, corpName: ylCropName, logoUrl: ylLogoUrl, agentId } = await getWeChatAppId(riversAppId, WeChatType.QY_WE_CHAT)
    if (corpId) {
      const redirectUrl = location.origin + location.pathname
      const authUrl = getAuthUrl(corpId, redirectUrl, agentId)
      // fork, 延用之前逻辑. 存储本地企业信息
      setLocalCorpDetail({
        appId: corpId,
        corpName: ylCropName.replace('易有料北京', '工深之星'),
        type: WeChatType.QY_WE_CHAT,
        corpLogo: ylLogoUrl,
      })
      location.href = authUrl
      return ''
    } else {
      Dialog.alert({ message: '未开通展业' })
      return ''
    }
  }
}
